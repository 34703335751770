/******************************************************************
 * 下層ページ共通
******************************************************************/

/* 共通パーツ
-----------------------------------------------------------------*/

.page-title{
  padding-bottom: 4px;
  margin-bottom: 20px;

  .title{
    display: flex;
    align-items: center;

    @media (max-width: 767px){
      display: block;
    }

    h2{
      font: 27px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      margin-right: 20px;

      @media (max-width: 767px){
        font-size: 20px;
      }

    }

    p{
      font: 20px "Nanum Gothic Coding", monospace;
      color: #cbad00;
      letter-spacing: 2.5px;

      @media (max-width: 767px){
        font-size: 14px;
        padding: 0 0 5px 0;
      }

    }
  }

  &::after{
    content: " ";
    display: block;
    width: 100%;
    height: 12px;
    background: linear-gradient(to right, #d2bb58, #f6e28e);

    @media (max-width: 767px){
      height: 8.4px;
    }

  }
}

.scroll{
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;

  @media (max-width: 767px){
    width: 84%;
    margin: 0 auto;
  }    

}

.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 50px;
  background: url("../img/common/common_btn_arrow.png") center right 14px no-repeat;
  background-size: 8px;
  border: 1px solid #92700b;
  transition: all .4s ease;

  @media (max-width: 1199px){
    width: 360px;
  }

  @media (max-width: 767px){
    background: url("../img/common/common_btn_arrow.png") center right 10px no-repeat;
    background-size: 6px;  
    width: 90%;
    height: 36px;
  }

  &:hover{
    background: url("../img/common/arrow_w_right.png") center right 14px no-repeat #92700b;
    background-size: 8px;

    @media (max-width: 767px){
      background: url("../img/common/arrow_w_right.png") center right 14px no-repeat #92700b;
      background-size: 8px;
    }

    a{
      color: #FFF;
    }
  
  }


  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    text-align: center;
    padding: 6px 0;

    @media (max-width: 1199px){
      font-size: 15px;
    }  

    @media (max-width: 767px){
      height: 36px;
      font-size: 16px;
    }  
  }

  &.height{
    height: 80px;

    @media (max-width: 767px){
      height: 60px;
    }      


    a{
      height: 80px;

      @media (max-width: 767px){
        height: 60px;
      }      

    }
  }

  &.spheight{
    @media (max-width: 767px){
      height: 60px;

      a{
        height: 60px;  
      }
    }      
  }
}


.btn-bg{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 50px;
  margin-bottom: 0;
  transition: all .4s ease;

  @media (max-width: 1199px){
    width: 360px;
  }

  @media (max-width: 767px){
    width: 90%;
    height: 36px;
    margin: 0 auto;
  }

  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 6px 0;

    span{
      background: url("../img/common/link_icon.png") center right no-repeat;
      background-size: 12px;
      font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      color: #FFF;
      padding: 0 30px;

      @media (max-width: 767px){
        font-size: 16px;
      }

    }
  }

/* -------- btn-bg background-image -------- */

  &.rikon{
    background: url("../img/kojin/kojin_rikon_bg.jpg") center no-repeat;
    background-size: cover;
    }

  &.souzoku{
    background: url("../img/kojin/kojin_souzoku_bg.jpg") center no-repeat;
    background-size: cover;
  }

  &.keiji{
    background: url("../img/kojin/kojin_keiji_bg.jpg") center no-repeat;
    background-size: cover;
  }

  /* -------- btn-bg hover -------- */

  &:hover{
    &.rikon{
      background: url("../img/common/hover_bg_rikon.jpg") center no-repeat;
      background-size: cover;  
    }

    &.souzoku{
      background: url("../img/common/hover_bg_souzoku.jpg") center no-repeat;
      background-size: cover;  
    }

    &.keiji{
      background: url("../img/common/hover_bg_keiji.jpg") center no-repeat;
      background-size: cover;  
    }

  }



}




/* 下層MV
-----------------------------------------------------------------*/

.mv-sub{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 420px;

  @media (max-width: 767px){
    height: 260px;
  }

  .mv-sub-title{
    h2{
      font: 42px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      margin-bottom: 6px;

      @media (max-width: 767px){
        font-size: 24px;
      }
    
      &.title-b{
        color: #4d4d4d;
      }

      &.title-w{
        color: #FFF;
      }
    }

    p{
      font: 20px "Nanum Gothic Coding", monospace;
      color: #cbad00;
      letter-spacing: 4px;

      @media (max-width: 767px){
        font-size: 14px;
        letter-spacing: 2px;
      }

    }

    &.inner{
      @media (max-width: 767px){
        width: 84%;
      }
    }
  }

  &.profile{
    align-items: flex-end;

    .mv-sub-title{
      background: linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .1));
      padding: 16px 40px 20px;

      @media (max-width: 767px){
        padding-left: 14px;
      }

    }
  }




/* -------- background-image -------- */  

&.profile{
  background: url("../img/profile/profile_mv.jpg") center center no-repeat;
  background-size: cover;

  @media (max-width: 767px){
    background: url("../img/profile/profile_mv_sp.jpg") top center no-repeat;
    background-size: cover;
  }
}

&.price{
  background: url("../img/price/price_mv.jpg") top center no-repeat;
  background-size: cover;

  @media (max-width: 767px){
    background: url("../img/price/price_mv_sp.jpg") top center no-repeat;
    background-size: cover;
  }
}

&.kojin{
  background: url("../img/kojin/kojin_mv.jpg") top center no-repeat;
  background-size: cover;

  @media (max-width: 767px){
    background: url("../img/kojin/kojin_mv_sp.jpg") top center no-repeat;
    background-size: cover;
  }
}

&.houjin{
  background: url("../img/houjin/houjin_mv.jpg") top center no-repeat;
  background-size: cover;

  @media (max-width: 767px){
    background: url("../img/houjin/houjin_mv_sp.jpg") top center no-repeat;
    background-size: cover;
  }
}

&.access{
  background: url("../img/access/access_mv.jpg") top center no-repeat;
  background-size: cover;

  @media (max-width: 767px){
    background: url("../img/access/access_mv_sp.jpg") top center no-repeat;
    background-size: cover;
  }
}



}


/* common-intro
-----------------------------------------------------------------*/

.common-intro{
  padding: 40px 0;

  @media (max-width: 767px){
    padding: 24px 0 34px;
  }

  .section-text{
    font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
    text-align: center;

    @media (max-width: 767px){
      font-size: 15px;
      text-align: left;
    }

    p{
      line-height: 2;
      margin-bottom: 20px;

      @media (max-width: 767px){
        line-height: 1.8;
        margin-bottom: 14px;
      }

      &:last-child{
        @media (max-width: 767px){
          margin-bottom: 30px;
        }
  
      }
    
    }
  }

  .btn{
    height: 36px;
    border: none;
    margin: 0 auto;

    @media (max-width: 767px){
      height: 30px;
    }

    a{
      height: 36px;
      background: #FFF;
      transition: all .4s ease;

      @media (max-width: 767px){
        height: 30px;
      }  
      
      span{
        background: url("../img/common/arrow_y_right.png") center right no-repeat;
        background-size: 8px;
        font: 16px 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif; 
        color: #333;
        padding: 0 20px;  
        transition: all .4s ease;


        @media (max-width: 767px){
          background: url("../img/common/arrow_y_right.png") center right no-repeat;
          background-size: 7px;  
          font-size: 14px;
        }
      
      }

    }

    &:hover{
      
      a{
        background: #92700b;  
 
        span{
          background: url("../img/common/arrow_w_right.png") center right no-repeat;
          background-size: 8px;
          color: #FFF; 
        }
      }
    }
  }
}


/* common-cont
-----------------------------------------------------------------*/

.common-cont{
  padding: 0 0 80px;

  @media (max-width: 767px){
    padding-bottom: 20px;
  }

  .cont-item{
    h3{
      padding: 8px 0;

      @media (max-width: 767px){
        padding: 6px 0;
      }    

      span{
        position: relative;
        display: block;
        width: 1180px;
        font: 27px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        padding-left: 50px;
        margin: 0 auto;

        @media (max-width: 1199px){
          width: 94%;
        }      

        @media (max-width: 767px){
          font-size: 20px;
          padding-left: 40px;
        }    

        &::before{
          position: absolute;
          left: 0;
          content: " ";
        }
      }
    }

    .item-wrap{
      width: 1180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0 30px;

      @media (max-width: 1199px){
        width: 94%;
      }    

      @media (max-width: 767px){
        display: block;
        padding-top: 20px;
      }    

      .section-text{
        width: 60%; 

        @media (max-width: 767px){
          width: 100%;
        }      

        p{
          margin-bottom: 20px;
        }
      }

      .btn{
        @media (max-width: 767px){
          margin: 0 auto;
        }      
      }

    }
  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{
  .mv-sub{
    //margin-top: 115px;
    overflow: hidden;

    @media (max-width: 999px){
      margin-top: 135px;
    }
  
    @media (max-width: 767px){
      margin-top: 73px;
    }
  
  }

  .top-message{
    .msg-item{
      .item-inner{
        h2{
          line-height: 1.5;
        }
      }
    }
  }
  
}





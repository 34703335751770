/******************************************************************
 * 弁護士費用
******************************************************************/

/* price-intro
-----------------------------------------------------------------*/

.price-intro{
  padding: 30px 0;

  .intro-catch{
    font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
    text-align: center;

    p{
      line-height: 2;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 30px;
      }

    }

    @media (max-width: 767px){
      width: 94%;
      font-size: 15px;
      margin: 0 auto;
    }

  }

  .intro-desc{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e3e3e3;
    padding: 30px 40px;
    margin: 0 auto;

    @media (max-width: 767px){
      display: block;
      padding: 20px 30px;
    }

    &.inner{
      width: 960px;

      @media (max-width: 999px){
        width: 84%;
      }
  
    }

    h2{
      background: url("../img/price/price_bracket_bg.png") no-repeat;
      background-size: 100% 100%;
      font: 42px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
      text-align: center;
      padding: 0 50px;
      margin-right: 60px;

      @media (max-width: 999px){
        font-size: 30px;
        padding: 0 20px;
        margin-right: 40px;
      }

      @media (max-width: 767px){
        width: 60%;
        font-size: 28px;
        padding: 0;
        margin: 0 auto 10px;
      }

    }

    .desc-text{
      h3{
        font: 22px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
        color: #92700b;

        @media (max-width: 767px){
          font-size: 20px;
          text-align: center;
          margin-bottom: 4px;
        }
  

      }
    }
  }
}


/* price-detail
-----------------------------------------------------------------*/

.price-detail{
  padding: 10px 0 80px;

  @media (max-width: 767px){
    padding-bottom: 40px;
  }    

  .inner{
    width: 960px;

    @media (max-width: 999px){
      width: 94%;
    }


    @media (max-width: 767px){
      width: 100%;
    }

  }

  .detail-intro{
    .intro-link{
      width: 640px;
      font-size: 24px;
      font-weight: bold;
      margin: 0 auto 20px;

      @media (max-width: 767px){
        width: 84%;
        font-size: 18px;
      }

      h2{
        text-align: center;
        border-bottom: 1px solid #cbad00;
        letter-spacing: 2px;
        padding-bottom: 6px;
        margin-bottom: 8px;

        a{
          transition: all .4s ease;

          span{
            background: url("../img/common/arrow_y_down.png") center right no-repeat;
            background-size: 14px;
            padding: 0 24px;
          }

          &:hover{
            opacity: .8;
          }  
  
        }

      }

      ul{
        display: flex;
        justify-content: center;

        @media (max-width: 767px){
          flex-wrap: wrap;
          padding-left: 10px;
        }    

        li{
          width: 22%;
          text-align: center;
          margin: 0 4px;

          @media (max-width: 767px){
            width: 40%;
            text-align: left;
            margin: 0 0 4px;
          }      

          a{
            position: relative;
            padding: 2px 20px 2px 32px;
            transition: all .4s ease;

            @media (max-width: 767px){
              padding: 2px 20px;
            }        

            &::before{
              position: absolute;
              content: " ";
              right: -2px;
              bottom: 6px;
              width: 14px;
              height: 14px;
              background: url("../img/common/arrow_y_down.png") center right no-repeat;
              background-size: 14px;  

              @media (max-width: 767px){
                bottom: 4px;
                width: 12px;
                height: 12px;
                background: url("../img/common/arrow_y_down.png") center right no-repeat;
                background-size: 12px;  
              }      
    
            }

            &:hover{
              opacity: .8;
            }  
  
  
          }

          &.souzoku{
            a{
              background: url("../img/price/price_icon01_b.png") left center no-repeat;
              background-size: contain;  

              @media (max-width: 767px){
                background: url("../img/price/price_icon01_b.png") left 7px center no-repeat;
                background-size: contain;    
                padding-left: 30px;
              }        
  
            }
          }

          &.rikon{
            a{
              background: url("../img/price/price_icon02_b.png") left center no-repeat;
              background-size: contain;  
              padding-left: 44px;

              @media (max-width: 767px){
                padding-left: 30px;
              }        
  
            }
          }

          &.keiji{
            a{
              background: url("../img/price/price_icon03_b.png") left center no-repeat;
              background-size: contain;  
              padding-left: 48px;

              @media (max-width: 767px){
                padding-left: 32px;
              }        
  
            }
          }

          &.other{
            a{
              background: url("../img/price/price_icon04_b.png") left center no-repeat;
              background-size: contain;  
              padding-left: 36px;

              @media (max-width: 767px){
                padding-left: 26px;
              }        
  
            }
          }
        }
      }
    }

    .intro-indent{
      text-indent: -1rem;
      margin-left: 1rem;
      margin-bottom: 80px;

      @media (max-width: 767px){
        width: 80%;
        margin: 0 auto 30px;
      }    

      &::before{
        content: "■";
        color: #bd8d00;
        margin-right: 2px;
      }
    }

    .intro-chart{
      h2{
        background: #333;
        font: 26px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
        color: #FFF;
        text-align: center;
        padding: 8px 0;
        margin-bottom: 14px;

        @media (max-width: 999px){
          font-size: 24px;
        }    

        @media (max-width: 767px){
          font-size: 20px;
          padding: 4px 0;
        }    
  
      }

      .scroll{
        width: 100%;

        table{
          width: 100%;
          border: 1px solid #333;
  
          @media (max-width: 767px){
            width: 600px;
            margin: 0 auto;
          }    
    
  
          tr{
  
            th, td{
              font-size: 18px;
              border-right: 1px solid #333;
              border-bottom: 1px solid #333;
              padding: 6px 0 6px 30px;
  
              @media (max-width: 999px){
                font-size: 15px;
              }        
  
              @media (max-width: 767px){
                font-size: 14px;
                padding-left: 20px;
              }        
  
              &:first-child{
                width: 44%;
              }
            }
    
            th{
              background: #e3e3e3;
              font-weight: bold;
            }
    
  
          }
  
        }  

      }

    }
  }
}


/* price-chart
-----------------------------------------------------------------*/

.price-chart{
  padding-top: 110px;
  padding-bottom: 80px;
  margin-top: -110px;

  @media (max-width: 767px){
    padding-bottom: 40px;
  }

  .inner{
    width: 960px;

    @media (max-width: 999px){
      width: 94%;
    }

    .chart-title{
      font: 26px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
      color: #FFF;
      text-align: center;
      padding: 6px 0;

      @media (max-width: 999px){
        font-size: 24px;
      }  

      @media (max-width: 767px){
        font-size: 20px;
        padding: 4px 0;
      }  

      span{
        padding: 4px 34px;
      }

      &.souzoku{
        background: #c78d33;

        span{
          background: url("../img/price/price_icon01_w.png") left center no-repeat;
          background-size: contain;
        }
      }

      &.rikon{
        background: #73a138;

        span{
          background: url("../img/price/price_icon02_w.png") left center no-repeat;
          background-size: contain;
          padding: 4px 42px;
        }

      }

      &.keiji{
        background: #2889b4;

        span{
          background: url("../img/price/price_icon03_w.png") left center no-repeat;
          background-size: contain;
          padding: 4px 50px;
        }

      }

      &.other{
        background: #333;

        span{
          background: url("../img/price/price_icon04_w.png") left center no-repeat;
          background-size: contain;
          padding: 4px 38px;
        }

      }
    }

    .chart-cont{
      margin-bottom: 30px;

      @media (max-width: 767px){
        width: 100%;
      }

      .scroll{
        width: 100%;
      }

      tr{
        th, td{
          border-bottom: 1px solid #333;
          padding: 20px 0;

          @media (max-width: 767px){
            display: block;
            width: 100%;
            padding: 4px 0;
          }
      
        }

        th{
          width: 280px;
          background: #e3e3e3;
          font-weight: bold;
          padding-left: 30px;

          @media (max-width: 767px){
            width: 100%;
            font-size: 14px;
            text-align: center;
            line-height: 1;
            padding: 10px 0;
          }

          span{
            font-size: 14px;
            font-weight: normal;

            @media (max-width: 767px){
              font-size: 12px;
            }
  
          }

        }

        td{
          padding-left: 20px;

          @media (max-width: 767px){
            padding: 20px 0;
          }

          .text{
            span{
              font-weight: bold;
              color: #92700b;
            }

          }

          .note{
            font-size: 14px;
            text-indent: -1rem;
            margin-left: 1rem;

            @media (max-width: 767px){
              font-size: 12px;
            }  

            &::before{
              content: "※";
              margin-right: 2px;
            }
          }

          ul{
            li{
              text-indent: -1rem;
              margin-left: 1rem;
  
              &::before{
                content: "■";
                color: #bd8d00;
              }

              span{
                font-weight: bold;
                color: #92700b;  
              }
            }
          }

          table{
            border: 1px solid #333;

            @media (max-width: 767px){
              width: 600px;
             }

            tr{

              th, td{
                border-bottom: 1px solid #333;
                border-right: 1px solid #333;
                padding: 4px 0 4px 16px;

                @media (max-width: 767px){
                  display: table-cell;
                }

                &:first-child{
                  width: 44%;

                  @media (max-width: 767px){
                    width: 230px;
                  }

                }
    
              }

              th{
                background: #e3e3e3;

                @media (max-width: 767px){
                  font-size: 14px;
                  text-align: left;
                }

              }

              td{

              }

            }
          }

        }
      }
    }
    .btn-row{
      display: flex;
      justify-content: center;

      @media (max-width: 767px){
        display: block;
      }

      li{
        margin: 0 20px;

        @media (max-width: 767px){
          width: 84%;
          margin: 0 auto 10px;
        }
  
      }
    }

    .other-text{
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px solid #333;

      a{
        color: #cbad00;

        &:hover{
          text-decoration: underline;
        }
      }
    }

  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{

  .price-detail{
    .inner{
      .detail-intro{
        .intro-link{
          ul{
            li{
              &.souzoku{
                a{
                  background: url("../img/price/price_icon01_b.png") left center no-repeat;
                  background-size: 20px;      
                }
              }
    
              &.rikon{
                a{
                  background: url("../img/price/price_icon02_b.png") left center no-repeat;
                  background-size: 30px;  
                  padding-left: 44px;
                }
              }
    
              &.keiji{
                a{
                  background: url("../img/price/price_icon03_b.png") left center no-repeat;
                  background-size: 35px;  
                  padding-left: 42px;
                }
              }
    
              &.other{
                a{
                  background: url("../img/price/price_icon04_b.png") left center no-repeat;
                  background-size: 30px;  
                  padding-left: 36px;
                }
              }
              }
          }
        }
      }

    }
  }


  .price-chart{
    .inner{
      .chart-title{
        padding: 12px 0;
      }  
    }
  }
  
}


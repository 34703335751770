/******************************************************************
 * ヘッダー
******************************************************************/

/* フローティングバナー
-----------------------------------------------------------------*/

.floatbnr{
  position: fixed;
  top: 15%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 224px;
  background: #cbad00;
  transition: all .4s ease;
  z-index: 2000;

  @media (max-width: 1049px){
    top: 24%;
    width: 38px;
    height: 150px;
  }

  @media (max-width: 767px){
    display: none;
  }

  a{
    display: block;

    @media (max-width: 767px){
      width: 100%;
    }

    img{
      width: 38px;

      @media (max-width: 1049px){
        width: 28px;
      }    

      @media (max-width: 767px){
        width: 40%;
      }
    
    }
  }

  &:hover{
    background: #bd8d00;
  }

  &.sp{
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    text-align: center;
  }
}


/* header-pc
-----------------------------------------------------------------*/

.header-nav-pc{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #FFF;
  padding: 10px 0 14px;
  z-index: 1000;
  transition: all .4s ease;

  @media (max-width: 767px){
    display: none;
  }

  .header-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 999px){
      margin-bottom: 0; 
    }                 

    .catch{
      font: 13px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 

      @media (max-width: 1099px){
        font-size: 11px;
      }  

    }

    .tel{
      display: flex;

      @media (max-width: 999px){
        display: flex;
        flex-direction: column;
        align-items: flex-end;      
      }               

      p{
        display: flex;
        align-items: center;
        line-height: 1;
        margin-right: 40px;

        @media (max-width: 1199px){
          margin-right: 20px;
        }  

        @media (max-width: 999px){
          margin: 0 0 8px;     
        }                 

        span{
          display: block;

          &.text{
            margin-right: 10px;
          }

          &.num{
            width: 160px;

            @media (max-width: 1199px){
              width: 130px;
            }  
    
          }
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  .header-bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 999px){
      display: block;   
    }                 

    .logo{
      width: 305px;

      @media (max-width: 1199px){
        width: 260px;
      }

      @media (max-width: 1099px){
        width: 220px;
      }  

      @media (max-width: 999px){
        margin-bottom: 20px; 
      }                 
  
    
    }

    .nav{
      display: flex;

      li{
        line-height: 1;
        margin-right: 16px;

        @media (max-width: 1099px){
          margin-right: 10px;
        }  

        @media (max-width: 999px){
          font-size: 14px;
          margin-right: 16px;
        }                     

        &.link-out{
          background: url("../img/common/link_icon.png") center right no-repeat;
          background-size: 14px;
          padding-right: 18px;

          @media (max-width: 1099px){
            background: url("../img/common/link_icon.png") center right no-repeat;
            background-size: 12px;
            padding-right: 14px;
          }  
    
        }

        &:last-child{
          margin-right: 0;
        }

        a{
          transition: all .4s ease;

          &:hover{
            color: #bd8d00;
          }
        }

        &.current{
          a{
            color: #bd8d00;
          }
        }


      }
    }

  }
}


/* header-sp
-----------------------------------------------------------------*/

.header-nav-sp{
  position: fixed;
  top: 0;
  width: 100%;
  background: #FFF;
  padding: 8px;
  z-index: 1000;
  transition: all .4s ease;

  @media (min-width: 768px){
    display: none;
  }

  .catch{
    font: 10px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
    margin-bottom: 14px;
    letter-spacing: -0.3px;
  }

  .logo{
    width: 180px;
  }

  .navToggle{
    position: fixed;
    top: 32px;
    right: 12px;
    width: 38px;
    height: 34px;
    z-index: 999;
    background: transparent;
    cursor: pointer;
    text-align: center;

    &::after{
      position: absolute;
      content: "MENU";
      bottom: -6px;
      right: -3px;    
      width: 100%;
      font-size: 10px;
    }

    span{
      display: block;
      position: absolute;
      width: 32px;
      cursor: pointer;
      border-bottom: solid 5px #bd8d00;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      transition: .4s ease-in-out;
      left: 6px;

      &:nth-child(1){
        top: 0px;
      }

      &:nth-child(2){
        top: 8px;
      }

      &:nth-child(3){
        top: 16px;
      }

      &:nth-child(4){
        border: none;
        color: #eee;
        font-size: 9px;
        font-weight: bold;
        top: 34px;
      }

    }

    &.active{
      z-index: 999;

      &::after{
        position: absolute;
        content: "CLOSE";
        bottom: -8px;
        right: -3px;    
        width: 100%;
        font-size: 10px;
      }  

      span{
        border-bottom: solid 5px #bd8d00;

        &:nth-child(1){
          top: 10px;
          left: 6px;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:nth-child(2){
          opacity: 0;
        }

        &:nth-child(3){
          top: 10px;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          transform: rotate(45deg);
        }

      }
    }

  }

  .globalMenuSp {
    position: fixed;
    z-index: 100;
    top: 73px;
    left: 0;
    bottom: 0;
    width: 100vw;
    //height: 100vh;
    color: #000;
    text-align: left;
    opacity: 0;
    background: rgba(255, 255, 255, .9);
    transition: all 0.6s;
    width: 100%;
    padding-bottom: 10px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    pointer-events: none;
    z-index: 999;

    h1{
      position: absolute;
      top: 16px;
      width: 100%;
      text-align: center;

      img{
        width: 200px;
      }
    }

    ul{

      li{
        background: url("../img/common/arrow_y_right.png") right 20px center no-repeat;
        background-size: 8px;
        margin: 0 auto;

        a{
          display: block;
          font-size: 16px;
          padding: 14px 0 14px 20px;
        }

        &.link-out{
          a{
            span{
              background: url("../img/common/link_icon.png") center right no-repeat;
              background-size: 14px;
              padding-right: 20px;    
            }
          }
        }

      }
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }

  }

}
/******************************************************************
 * セクション共通部分
******************************************************************/

/* Breakpoint + Margin adjustment
-----------------------------------------------------------------*/

.inner{
  width: 1180px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1199px){
    width: 94%;
  }

  &.access-inner{
    @media (max-width: 767px){
      width: 100%;
    }      
  }

}

@media (min-width: 768px){
  .sp{
    display: none;
  }
}

@media (max-width: 767px){
  .pc{
    display: none;
  }
}

main{
  margin-top: 115px;
  display: block;
  overflow: hidden;

  @media (max-width: 999px){
    margin-top: 135px;
  }

  @media (max-width: 767px){
    margin-top: 73px;
  }

}

.margin-b10{
  margin-bottom: 10px;
}

.margin-b20{
  margin-bottom: 20px;

  @media (max-width: 767px){
    margin-bottom: 10px;
  }

}



/* Parts
-----------------------------------------------------------------*/

.section-title{
  text-align: center;
  margin-bottom: 40px;

  p{
    margin: 0 auto 10px;

    @media (max-width: 767px){
      margin-bottom: 6px;
    }

  }

  h2{
    font: 27px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

    @media (max-width: 767px){
      font-size: 20px;
      letter-spacing: 0;
    }

  }
}

.section-text{
  margin-bottom: 10px;

  p{
    margin-bottom: 10px;
  }
}


/* pagelink
-----------------------------------------------------------------*/

.pagelink{
  padding-top: 110px;
  margin-top: -110px;

  @media (max-width: 767px){
    padding-top: 80px;
    margin-top: -80px;
  }
}



/* 404 Page
-----------------------------------------------------------------*/

.page-error{
  padding: 80px 0;

  @media(max-width: 767px){
    padding: 42px 0;
  }

  .section-title{
    margin-bottom: 30px;

    p{
      width: 150px;

      @media (max-width: 767px){
        width: 110px;
      }
    }
  }

  .section-text{
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px){
      font-size: 14px;
      padding: 0 10px;
      margin-bottom: 35px;
    }

  }

  .btn{
    width: 320px;
    text-align: center;
    margin: 0 auto;
    background: url("../img/common/arrow_y_right.png") center right 80px no-repeat;
    background-size: 8px;
    border: none;

    @media(max-width: 767px){
      background: url("../img/common/arrow_y_right.png") center right 40px no-repeat;
      background-size: 8px;  
      width: 224px;
    }

    a{
      display: flex;
      color: #4d4d4d;
      border: 1px solid #cbad00;
      padding: 4px 0;
      transition: all .4s ease;

      @media (max-width: 767px){
        padding: 15px 0;
      }

    }

    &:hover{
      background: url("../img/common/arrow_w_right.png") center right 80px no-repeat #cbad00;
      background-size: 8px;
      color: #FFF;
    }

  }


}



/******************************************************************
 * IE調整
******************************************************************/

.ie{

  .page-error{
    margin-top: 115px;
    overflow: hidden;

    @media (max-width: 999px){
      margin-top: 135px;
    }
  
    @media (max-width: 767px){
      margin-top: 73px;
    }
  }
}


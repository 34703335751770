/******************************************************************
 * TOP
******************************************************************/

/* MV - PC
-----------------------------------------------------------------*/

.mv{
  position: relative;
  width: 100%;
  height: 565px;

  @media (max-width: 1280px){
    height: 500px;
  }

  @media (max-width: 1099px){
    height: 460px;
  }

  @media (max-width: 899px){
    height: 380px;
  }

  @media (max-width: 799px){
    height: 340px;
  }

  @media (max-width: 767px){
    height: 400px;
  }

  .mv-slide{
    overflow: hidden;

    &.wide{
      @media (max-width: 640px){
        display: none;
      }
    }

    .mv-slide{
      width: 100%;
      height: 565px;

      @media (max-width: 1280px){
        height: 500px;
      }

      @media (max-width: 1099px){
        height: 460px;
      }

      @media (max-width: 899px){
        height: 380px;
      }

      @media (max-width: 799px){
        height: 340px;
      }


      &.slide01{
        background: url("../img/top/top_mv01.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide02{
        background: url("../img/top/top_mv02.jpg") top no-repeat;
        background-size: cover;

      }
    }

  }

  .inner{
    @media (max-width: 767px){
      position: relative;
    }

    .mv-title{
      position: absolute;
      bottom: -30px;
      width: 570px;
      background: linear-gradient(to right, rgba(203, 173, 0, .8), rgba(255, 255, 220, .8));
      font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      color: #FFF;
      padding: 40px 20px;
      margin-right: auto;

      @media (max-width: 1149px){
        width: 520px;
      }

      @media (max-width: 1059px){
        width: 442px;
      }

      @media (max-width: 899px){
        padding: 20px;
      }  

      @media (max-width: 767px){
        bottom: 16px;
        width: 100%;
        padding: 24px 14px 20px;
      }

      .img{
        position: absolute;
        top: -30px;
        right: -40px;
        width: 225px;
        mix-blend-mode: overlay;

        @media (max-width: 1149px){
          top: -26px;
          right: -26px;
          width: 200px;
        }  

        @media (max-width: 1059px){
          width: 160px;
        }  

        @media (max-width: 767px){
          top: -16px;
          right: -10px;
          width: 130px;
        }
      }

      .title{
        font-size: 34px;
        margin-bottom: 10px;

        @media (max-width: 899px){
          font-size: 28px;
        }  

        @media (max-width: 640px){
          font-size: 26px;
          margin-bottom: 4px;
        }

      }

      .text{
        font-size: 20px;

        @media (max-width: 1149px){
          font-size: 17px;
        }  

        @media (max-width: 899px){
          font-size: 15px;
        }  

        @media (max-width: 640px){
          font-size: 12px;
        }

      }
    }


  }
}

/* MV - SP
-----------------------------------------------------------------*/

.mv-slide{
  &.responsive{
    @media (min-width: 641px){
      display: none;
    }

    .mv-slide{
      width: 100%;
      height: 400px;

      &.slide01{
        background: url("../img/top/top_mv01_sp.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide02{
        background: url("../img/top/top_mv02_sp.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide03{
        background: url("../img/top/top_mv03_sp.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide04{
        background: url("../img/top/top_mv04_sp.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide05{
        background: url("../img/top/top_mv05_sp.jpg") top no-repeat;
        background-size: cover;
      }

      &.slide06{
        background: url("../img/top/top_mv06_sp.jpg") top no-repeat;
        background-size: cover;
      }

    }


  }

}


/* top-info
-----------------------------------------------------------------*/

.top-info{
  padding: 60px 0 30px;

  @media (max-width: 767px){
    padding: 30px 0 14px;
  }

  .title{
    display: flex;
    margin-bottom: 10px;

    @media (max-width: 767px){
      display: block;
    }

    p{
      width: 188px;
      margin-right: 20px;

      @media (max-width: 999px){
        width: 160px;
      }

      @media (max-width: 767px){
        width: 130px;
        margin-bottom: 4px;
      }
    }

    h2{
      font: 20px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

      @media (max-width: 999px){
        font-size: 16px;
      }

    }
  }
  .info-text{
    text-indent: -1rem;
    margin-left: 1rem;
    line-height: 1.7;
    & + p{
      margin-top: 10px;
    }
  }

}


/* top-reason
-----------------------------------------------------------------*/

.top-reason{
  background: linear-gradient(to bottom, #d2bb58, #f6e28e);
  padding: 50px 0 30px;

  @media (max-width: 767px){
    padding: 24px 0 14px;
  }

  .section-title{
    @media (max-width: 767px){
      margin-bottom: 0;
    }

    p{
      width: 66px;

      @media (max-width: 767px){
        width: 40px;
      }

    }
  }

  .reason-wrap{

    .item-wrap{
      position: relative;

      .item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 30px;
        margin-bottom: 30px;

        @media (max-width: 767px){
          display: block;
          padding-top: 20px;
          margin-bottom: 20px;
        }

        &.inner{
          width: 64%;

          @media (max-width: 1329px){
            width: 74%;
          }

          @media (max-width: 1199px){
            width: 84%;
          }

          @media (max-width: 999px){
            width: 90%;
          }

          @media (max-width: 767px){
            width: 100%;
          }

        }

        h3{
          width: 230px;
          background: url("../img/top/top_reason_bracket.png") no-repeat;
          background-size: 100% 100%;
          font: 42px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          text-align: center;
          margin-right: 30px;

          @media (max-width: 1199px){
            width: 190px;
            font-size: 34px;
          }

          @media (max-width: 999px){
            width: 170px;
            font-size: 30px;
            margin-right: 14px;
          }

          @media (max-width: 767px){
            margin: 0 auto 18px;
          }

        }

        .desc{
          width: 60%;
          font: 20px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          line-height: 1.75;

          @media (max-width: 1199px){
            font-size: 18px;
          }

          @media (max-width: 999px){
            font-size: 16px;
          }

          @media (max-width: 767px){
            width: 100%;
            line-height: 1.8;
            text-align: center;
            padding: 0 10px;
          }

        }
      }

      .accMore{
        position: absolute;
        top: 50px;
        left: 0;
        right: -20px;
        width: 68%;
        text-align: right;
        margin: auto;

        @media (max-width: 1199px){
          right: -10px;
        }

        @media (max-width: 999px){
          right: 2px;
          width: 84%;
        }

        @media (max-width: 767px){
          position: unset;
          top: 138px;
          left: 0;
          right: 0;
          width: auto;
          text-align: center;
          margin-bottom: 20px;
        }

        a{
          background: url("../img/common/arrow_w_down.png") bottom center no-repeat;
          background-size: 14px;
          padding-bottom: 16px;
          transition: all .4s ease;
          cursor: pointer;

          &:hover{
            color: #FFF;

            @media (max-width: 767px){
              color: #333;
            }

          }
      }

    }


    .accord{
      display: none;
      background: #FFF;
      padding: 20px 0 60px;

      .inner{
        position: relative;
        width: 64%;

        @media (max-width: 1199px){
          width: 84%;
        }

        @media (max-width: 999px){
          width: 90%;
        }

        @media (max-width: 767px){
          width: 92%;
        }

        ul{
          li{
            text-indent: -1.2em;
            margin-left: 1.2em;
            margin-bottom: 24px;

            &::before{
              content: "■";
              color: #bd8d00;
              margin-right: 4px;
            }

            &:last-child{
              margin-bottom: 0;
            }
          }
        }

        .accClose{
          position: absolute;
          bottom: -39px;
          right: -14px;

          @media (max-width: 1199px){
            right: 100px;
          }

          @media (max-width: 999px){
            right: 50px;
          }

          @media (max-width: 767px){
            right: 0;
            left: 0;
            text-align: center;
          }

          a{
            background: url("../img/common/arrow_y_up.png") bottom center no-repeat;
            background-size: 14px;
            padding-bottom: 16px;
            transition: all .4s ease;
            cursor: pointer;

            &:hover{
              color: #bd8d00;
            }
        }
        }

      }

    }

    &:nth-of-type(1){

      .item{
        @media (max-width: 767px){
          padding-top: 20px;
        }
      }

      .accMore{
        @media (max-width: 767px){
          top: 140px;
        }
      }
    }

    }
  }

}


/* top-links
-----------------------------------------------------------------*/

.top-links{
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 767px){
    display: block;
    padding: 30px 0;
    margin-bottom: 0;
  }

  .link-item{
    position: relative;
    width: 33.3333%;

    @media (max-width: 767px){
      width: 80%;
      margin: 0 auto;
    }

    @media (max-width: 325px){
      width: 90%;
      margin: 0 auto;
    }


    .item-inner{
      width: 100%;
      background: rgba(255, 255, 255, .5);
      padding: 80px 0 24px;

      @media (max-width: 999px){
        padding-top: 60px;
      }

      .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 230px;
        height: 230px;
        font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        color: #FFF;
        text-align: center;
        padding: 20px 10px;
        margin: 0 auto 40px;

        @media (max-width: 999px){
          width: 200px;
          height: 200px;
          margin-bottom: 28px;
        }

        @media (max-width: 767px){
          width: 60%;
          height: auto;
        }

        @media (max-width: 325px){
          width: 70%;
        }


        p{
          font-size: 20px;

          @media (max-width: 767px){
            font-size: 16px;
          }

        }

        h2{
          font-size: 40px;
          line-height: 1.5;

          @media (max-width: 999px){
            font-size: 34px;
          }

          @media (max-width: 767px){
            font-size: 30px;
          }

        }

      }

      .btn{
        width: 230px;
        background: rgba(255, 255, 255, .8);
        text-align: center;
        margin: 0 auto;
        transition: all .4s ease;
        border: none;

        @media (max-width: 999px){
          width: 200px;
        }

        @media (max-width: 767px){
          width: 60%;
        }

        a{
          display: flex;
          background: url("../img/common/link_icon.png") center right 46px no-repeat;
          background-size: 12px;
          font: 20px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          color: #4d4d4d;

          @media (max-width: 999px){
            font-size: 16px;
            padding: 4px 0;
          }

          @media (max-width: 767px){
            background: url("../img/common/link_icon.png") center right 30px no-repeat;
            background-size: 12px;
            font-size: 16px;
          }

        }

        &:hover{
          background: linear-gradient(to left, rgba(203, 173, 0, .8), rgba(255, 255, 220, .8));
        }
      }

    }

    &.item01{
      background: url("../img/top/top_links_bg01.jpg") no-repeat;
      background-size: cover;

      .title{
        background: rgba(199, 141, 51, .8);
      }
    }

    &.item02{
      background: url("../img/top/top_links_bg02.jpg") no-repeat;
      background-size: cover;

      .title{
        background: rgba(115, 161, 56, .8);
      }
    }

    &.item03{
      background: url("../img/top/top_links_bg03.jpg") no-repeat;
      background-size: cover;

      .title{
        background: rgba(40, 137, 180, .8);
      }
    }
  }
}


/* top-message
-----------------------------------------------------------------*/

.top-message{
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px){
    display: block;
  }

  .msg-item{
    width: 50%;

    @media (max-width: 767px){
      width: 100%;
    }

    .item-inner{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, .5);
      padding: 40px 0;

      @media (max-width: 767px){
        padding: 30px 0;
      }

      h2{
        font: 42px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        color: #1a1a1a;
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: 999px){
          font-size: 34px;
        }

        @media (max-width: 767px){
          font-size: 28px;
          line-height: 1.3;
        }

      }

      .text{
        width: 412px;
        height: 180px;
        background: rgba(255, 255, 255, .7);
        padding: 20px 30px;
        margin: 0 auto 10px;

        @media (max-width: 999px){
          width: 380px;
          height: 150px;
        }

        @media (max-width: 767px){
          width: 80%;
          height: auto;
          padding: 14px 20px;
          margin-bottom: 14px;
        }
      }

      .btn{
        position: relative;
        width: 412px;
        text-align: center;
        margin: 0 auto;
        border: none;

        @media (max-width: 999px){
          width: 380px;
        }

        @media (max-width: 767px){
          width: 80%;
        }

        &::before{
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to left, rgba(203, 173, 0, .8), rgba(255, 255, 220, .8));
          transition: all .4s ease;
          z-index: 1;
        }

        &::after{
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, .8);
          transition: all .4s ease;
          z-index: 0;
        }

        a{
          display: flex;
          background: url("../img/common/arrow_w_right.png") center right 130px no-repeat;
          background-size: 8px;
          color: #4d4d4d;
          padding: 4px 0;
          z-index: 10;

          @media (max-width: 999px){
            background: url("../img/common/arrow_w_right.png") center right 100px no-repeat;
            background-size: 8px;
          }

          @media (max-width: 767px){
            background: url("../img/common/arrow_w_right.png") center right 60px no-repeat;
            background-size: 8px;
            font-size: 16px;
            padding: 2px 0;
          }

        }

        &:hover{
          background: transparent;

          &::before{
            opacity: 0;
          }

          a{
            background: url("../img/common/arrow_y_right.png") center right 130px no-repeat;
            background-size: 8px;

            @media (max-width: 999px){
              background: url("../img/common/arrow_y_right.png") center right 100px no-repeat;
              background-size: 8px;
            }
  
            @media (max-width: 767px){
              background: url("../img/common/arrow_y_right.png") center right 60px no-repeat;
              background-size: 8px;
            }  

          }
        }

      }

    }

    &.item01{
      background: url("../img/top/top_msg_bg01.jpg") no-repeat;
      background-size: cover;
    }

    &.item02{
      background: url("../img/top/top_msg_bg02.jpg") no-repeat;
      background-size: cover;
    }

  }
}


/* top-flow
-----------------------------------------------------------------*/

.top-flow{
  padding: 60px 0 40px;

  @media (max-width: 767px){
    padding: 40px 0;
  }

  .section-title{
    p{
      width: 64px;

      @media (max-width: 767px){
        width: 40px;
      }

    }
  }

  .flow-wrap{
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px){
      display: block;
    }

    .flow-menu{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 320px;

      @media (max-width: 1099px){
        width: 250px;
      }

      @media (max-width: 899px){
        width: 220px;
      }

      @media (max-width: 767px){
        flex-direction: row;
        width: 100%;
        margin-bottom: 14px;
      }

      li{
        position: relative;
        padding: 44px 0 44px 80px;
        cursor: pointer;

        @media (max-width: 1099px){
          padding-left: 40px;
        }

        @media (max-width: 767px){
          width: 32%;
          padding: 16px 0 16px 16px;
        }

        span{
          font: 42px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          color: #4d4d4d;
          transition: all .4s ease;

          @media (max-width: 1099px){
            font-size: 32px;
          }

          @media (max-width: 767px){
            font-size: 20px;
          }

        }

        &.is-active{
          cursor: unset;

          &::after{
            position: absolute;
            top: 0;
            right: -82px;
            content: " ";
            width: 82px;
            height: 100%;
            background: #d2bb58;

            @media (max-width: 1099px){
              right: -102px;
              width: 102px;
            }

            @media (max-width: 767px){
              content: none;
            }

          }

          &:hover{
            span{
              color: #4d4d4d;
            }
          }
        }

        &:nth-of-type(1){
          background: url("../img/top/top_flow_tab_bg01.png") center left 60px no-repeat, url("../img/common/arrow_y_right.png") center right 80px no-repeat #e3e3e3;
          background-size: 60px, 10px;

          @media (max-width: 1099px){
            background: url("../img/top/top_flow_tab_bg01.png") center left 20px no-repeat, url("../img/common/arrow_y_right.png") center right 20px no-repeat #e3e3e3;
            background-size: 40px, 10px;
          }

          @media (max-width: 767px){
            background: url("../img/top/top_flow_tab_bg01.png") center left 4px no-repeat, url("../img/common/arrow_y_down.png") center right 6px no-repeat #e3e3e3;
            background-size: 20px, 10px;
          }

          &.is-active{
            background: url("../img/top/top_flow_tab_bg01.png") center left 60px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
            background-size: 60px, 100%;

            @media (max-width: 1099px){
              background: url("../img/top/top_flow_tab_bg01.png") center left 20px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 40px, 100%;
            }

            @media (max-width: 767px){
              background: url("../img/top/top_flow_tab_bg01.png") center left 4px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 20px, 100%;
            }

          }

        }

        &:nth-of-type(2){
          background: url("../img/top/top_flow_tab_bg02.png") center left 60px no-repeat, url("../img/common/arrow_y_right.png") center right 80px no-repeat #e3e3e3;
          background-size: 60px, 10px;

          @media (max-width: 1099px){
            background: url("../img/top/top_flow_tab_bg02.png") center left 20px no-repeat, url("../img/common/arrow_y_right.png") center right 20px no-repeat #e3e3e3;
            background-size: 40px, 10px;
          }

          @media (max-width: 767px){
            background: url("../img/top/top_flow_tab_bg02.png") center left 4px no-repeat, url("../img/common/arrow_y_down.png") center right 6px no-repeat #e3e3e3;
            background-size: 20px, 10px;
          }

          &.is-active{
            background: url("../img/top/top_flow_tab_bg02.png") center left 60px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
            background-size: 60px, 100%;

            @media (max-width: 1099px){
              background: url("../img/top/top_flow_tab_bg02.png") center left 20px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 40px, 100%;
            }

            @media (max-width: 767px){
              background: url("../img/top/top_flow_tab_bg02.png") center left 4px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 20px, 100%;
            }

          }

        }

        &:nth-of-type(3){
          background: url("../img/top/top_flow_tab_bg03.png") center left 60px no-repeat, url("../img/common/arrow_y_right.png") center right 80px no-repeat #e3e3e3;
          background-size: 60px, 10px;

          @media (max-width: 1099px){
            background: url("../img/top/top_flow_tab_bg03.png") center left 20px no-repeat, url("../img/common/arrow_y_right.png") center right 20px no-repeat #e3e3e3;
            background-size: 40px, 10px;
          }

          @media (max-width: 767px){
            background: url("../img/top/top_flow_tab_bg03.png") center left 4px no-repeat, url("../img/common/arrow_y_down.png") center right 6px no-repeat #e3e3e3;
            background-size: 20px, 10px;
          }

          &.is-active{
            background: url("../img/top/top_flow_tab_bg03.png") center left 60px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
            background-size: 60px, 100%;

            @media (max-width: 1099px){
              background: url("../img/top/top_flow_tab_bg03.png") center left 20px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 40px, 100%;
            }

            @media (max-width: 767px){
              background: url("../img/top/top_flow_tab_bg03.png") center left 4px no-repeat, linear-gradient(to left, #d2bb58, #f6e28e);
              background-size: 20px, 100%;
            }

          }

        }

        &:hover{
          span{
            color: #cbad00;
          }
        }

      }
    }

    .tabContent{
      display: none;
      width: 67%;
      height: 600px;
      background: #d2bb58;
      padding: 40px;
      z-index: 100;

      @media (max-width: 1099px){
        width: 68%;
        height: 560px;
      }

      @media (max-width: 767px){
        width: 100%;
        height: auto;
        padding: 30px 20px;
      }

      h3{
        font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        border-bottom: 1px solid #FFF;
        padding-bottom: 6px;
        margin-bottom: 30px;

        @media (max-width: 767px){
          font-size: 15px;
          padding-bottom: 10px;
          margin-bottom: 14px;
        }

      }

      .flow-img{
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: 767px){
          margin-bottom: 30px;
        }

      }

      .textbox{
        margin-bottom: 60px;

        @media (max-width: 767px){
          margin-bottom: 40px;
        }

        .text{
          margin-bottom: 20px;
        }

      }

      .btn{
        width: 240px;
        background: #FFF;
        text-align: center;
        transition: all .4s ease;
        border: none;

        @media (max-width: 767px){
          width: 100%;
          text-align: left;
        }

        a{
          display: flex;
          background: url("../img/common/arrow_y_right.png") center right 20px no-repeat;
          background-size: 10px;
          font: 24px 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
          font-weight: bold;
          padding: 10px 0;

          @media (max-width: 767px){
            background: url("../img/common/arrow_y_right.png") center right 20px no-repeat;
            background-size: 6px;
            font-size: 17px;
            padding: 10px 0;
          }

        }

        &:hover{
          opacity: .8;

          a{
            color: #333;
          }
        }

      }

      &.is-active{
        display: block;
      }

      &.cont01{
        .form-flow{
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;

          @media (max-width: 767px){
            display: block;
          }


          .form{
            display: flex;
            justify-content: center;
            width: 28%;
            background: #FFF;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
            transition: all .4s ease;

            @media (max-width: 1199px){
              font-size: 20px;
            }

            @media (max-width: 767px){
              width: 100%;
              font-size: 17px;
              text-align: left;
              margin-bottom: 14px;
            }  

            a{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              background: url("../img/common/arrow_y_right.png") center right 5% no-repeat;
              background-size: 10px;
              line-height: 1.4;

              @media (max-width: 767px){
                justify-content: unset;
                padding: 6px 0 6px 20px;
              }  
  
            }

            &:hover{
              opacity: .8;
            }

          }

          ul{
            width: 70%;

            @media (max-width: 767px){
              width: 100%;
            }      

            li{
              display: flex;
              margin-bottom: 14px;

              @media (max-width: 767px){
                margin-bottom: 8px;
              }      

              .office{
                display: block;
                width: 233px;
                font-size: 22px;
                font-weight: bold;
                text-align: center;
                border: 1px solid #FFF;
                margin-right: 12px;

                @media (max-width: 1199px){
                  font-size: 16px;
                }

                @media (max-width: 767px){
                  width: 49%;
                  font-size: 14px;
                }      
    
              }

              a{
                display: block;
                width: 244px;

                @media (max-width: 767px){
                  display: flex;
                  align-items: center;
                  width: 49%;
                }      
    
              }

              &:last-child{
                margin-bottom: 0;
              }
              
            }
          }
        }
      }

      &.cont02, &.cont03{
        h3{
          margin-bottom: 16px;
        }
      }
    }
  }
}



/* top-youtube
-----------------------------------------------------------------*/

.top-youtube{
  padding: 20px 0 60px;

  @media (max-width: 767px){
    padding-bottom: 50px;
  }


  .section-title{
    p{
      width: 108px;

      @media (max-width: 767px){
        width: 100px;
      }

    }

    h2{
      font-size: 24px;

      @media (max-width: 767px){
        font-size: 20px;
      }

    }
  }

  .youtube-wrap{
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px){
      display: block;
    }

    .channel{
      width: 47%;

      @media (max-width: 767px){
        width: 100%;
        margin-bottom: 40px;

        &:last-child{
          margin-bottom: 0;
        }
      }

      h3{
        font: 24px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        text-align: center;
        margin-bottom: 14px;

        @media (max-width: 1199px){
          font-size: 20px;
        }

        @media (max-width: 999px){
          font-size: 18px;
        }

        @media (max-width: 767px){
          font-size: 20px;
          margin-bottom: 8px;
        }

      }

      .thumb{
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px){
          display: block;
        }

        li{
          width: 48%;

          @media (max-width: 767px){
            width: 100%;
            margin-bottom: 20px;

            &:last-child{
              margin-bottom: 0;
            }

          }

          img{
            width: 100%;
          }

        }
      }
    }
  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{
  .mv{
    //margin-top: 115px;
    overflow: hidden;

    .inner{
      .mv-title{
        .img{
          opacity: .9;
        }
      }
    }
  
  }

  .top-message{
    .msg-item{
      .item-inner{
        h2{
          line-height: 1.5;
        }
      }
    }
  }
  
}








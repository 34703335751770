/******************************************************************
 * 法人のお客様へ
******************************************************************/

/* common-intro
-----------------------------------------------------------------*/

.common-intro{
  &.houjin{
    background: #4d4d4d;
    color: #FFF;  

    .btn{
      background: none;
      
      a{
        background: rgba(255, 255, 255, .7);
        color: #333;
        transition: all .4s ease;

        &:hover{
          background: #92700b;
        }
      }
    }
  
  }

}


/* common-cont
-----------------------------------------------------------------*/

.common-cont{

  &.houjin{
    .cont-item{
      background: linear-gradient(to right, #d2bb58, #f6e28e);
  
      h3{
        background: #FFF;
  
        span{
          color: #333;
        }
  
      }
  
      .item-wrap{
  
        .section-text{
          p{
            span{
              color: #FFF;
              font-weight: bold;
            }
          }
        }
  
        .btn{
          border: none;
  
          a{
            background: url("../img/common/common_btn_arrow.png") center right 10px no-repeat #FFF;
            background-size: 8px;
            transition: all .4s ease;

            @media (max-width: 767px){
              background: url("../img/common/common_btn_arrow.png") center right 10px no-repeat #FFF;
              background-size: 6px;    
            }
        
          }

          &:hover{
      
            a{
              background: url("../img/common/arrow_w_right.png") center right 10px no-repeat #92700b;
              background-size: 8px;
              color: #FFF; 
       
            }
          }
        }
  
      }

/* -------- h3 background-image -------- */  
  
      &.item01{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon01.svg");
              width: 42px;

              @media (max-width: 767px){
                top: 4px;
                width: 34px;
              }

            }
          }
        }
      }

      &.item02{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon02.svg");
              top: 2px;
              width: 38px;

              @media (max-width: 767px){
                top: 0;
                width: 30px;
              }

            }
          }
        }
      }

      &.item03{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon03.svg");
              width: 38px;

              @media (max-width: 767px){
                top: -2px;
                width: 30px;
              }

            }
          }
        }
      }

      &.item04{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon04.svg");
              width: 32px;

              @media (max-width: 767px){
                top: -2px;
                width: 24px;
              }

            }
          }
        }
      }

      &.item05{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon05.svg");
              top: -2px;
              width: 34px;

              @media (max-width: 767px){
                width: 28px;
              }

            }
          }
        }
      }

      &.item06{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon06.svg");
              width: 32px;

              @media (max-width: 767px){
                top: -2px;
                width: 24px;
              }

            }
          }
        }
      }

      &.item07{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon07.svg");
              top: -2px;
              width: 26px;

              @media (max-width: 767px){
                top: -4px;
                width: 20px;
              }

            }
          }
        }
      }

      &.item08{
        h3{
          span{
            &::before{
              content: url("../img/houjin/houjin_icon08.svg");
              top: 2px;
              width: 38px;

              @media (max-width: 767px){
                top: 0;
                width: 30px;
              }

            }
          }
        }
      }

    }
  
  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{
  .common-cont{
    &.houjin{
      .cont-item{

        &.item01{
          h3{
            span{
              &::before{
              }
            }
          }
        }

        &.item02{
          h3{
            span{
              &::before{
                top: 0px;
                width: 34px;
              }
            }
          }
        }

        &.item03{
          h3{
            span{
              &::before{
                top: -2px;
                width: 35px;
              }
            }
          }
        }

        &.item04{
          h3{
            span{
              &::before{
                top: -3px;
                width: 28px;
              }
            }
          }
        }

        &.item05{
          h3{
            span{
              &::before{
                top: -4px;
                width: 28px;
              }
            }
          }
        }

        &.item06{
          h3{
            span{
              &::before{
                top: -4px;
                width: 26px;
              }
            }
          }
        }

        &.item07{
          h3{
            span{
              &::before{
                top: -5px;
                width: 22px;
            }
          }
        }
      }

        &.item08{
          h3{
            span{
              &::before{
                top: -3px;
                width: 34px;
              }
            }
          }
        }
        

      }
    }
  }
}
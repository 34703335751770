/******************************************************************
 * プライバシーポリシー
******************************************************************/

/* privacy-policy
-----------------------------------------------------------------*/

.privacy-policy{
  width: 100%;
  max-width: 1060px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 0 80px;
  margin: 0 auto;

  @media (max-width: 1199px){
    padding: 20px 20px 80px;
  }

  @media (max-width: 767px){
    padding: 35px 20px 50px;
  }

  .section-text{
    a{
      color: #cbad00;
      
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .privacy-wrap{
    margin-top: 40px;

    @media (max-width: 767px){
      margin-top: 23px;
    }

    .box{
      background: #f0f0f0;
      padding: 30px 50px;
      margin-bottom: 14px;

      @media (max-width: 767px){
        padding: 20px 35px;
      }

      h3{
        font: 20px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        margin-bottom: 20px;

        @media (max-width: 767px){
          font-size: 18px;
        }

      }

      p{
        a{
          color: #cbad00;

          &:hover{
            text-decoration: underline;
          }
        }
      }

      .small{
        font-size: 14px;
        margin-top: 20px;

        p{
          font-weight: 600;
        }

        ul{
          li{
            position: relative;
            padding-left: 18px;

            @media (max-width: 767px){
              padding-left: 13px;
            }

            &::before{
              position: absolute;
              display: block;
              top: 0;
              left: 0;
              content: "※";
              font-size: 14px;

              @media (max-width: 767px){
                font-size: 10px;
              }

            }
          }
        }

      }

      &.box01{
        background: url("../img/privacy/privacy_01.png") top 15px left 30px no-repeat #f0f0f0;
        background-size: 30px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_01.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 24px;
        }

      }

      &.box02{
        background: url("../img/privacy/privacy_02.png") top 15px left 30px no-repeat #f0f0f0;
        background-size: 38px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_02.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 30px;
        }

      }

      &.box03{
        background: url("../img/privacy/privacy_03.png") top 15px left 30px no-repeat #f0f0f0;
        background-size: 34px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_03.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 30px;
        }

      }

      &.box04{
        background: url("../img/privacy/privacy_04.png") top 15px left 30px no-repeat #f0f0f0;
        background-size: 36px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_04.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 30px;
        }

      }

      &.box05{
        background: url("../img/privacy/privacy_05.png") top 15px left 30px no-repeat #f0f0f0;
        background-size: 38px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_05.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 30px;
        }

      }

      &.box06{
        background: url("../img/privacy/privacy_06.png") top 18px left 30px no-repeat #f0f0f0;
        background-size: 36px;

        @media (max-width: 767px){
          background: url("../img/privacy/privacy_06.png") top 9px left 20px no-repeat #f0f0f0;
          background-size: 30px;
        }

      }

    }
  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{

  .privacy-policy{
    margin-top: 115px;
    overflow: hidden;

    @media (max-width: 999px){
      margin-top: 135px;
    }
  
    @media (max-width: 767px){
      margin-top: 73px;
    }
  }
}




/******************************************************************
 * 相談予約
*******************************************************************

/* 入力画面
-----------------------------------------------------------------*/
.contact-form {
  width: 100%;
  max-width: 1060px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 0 80px;
  margin: 0 auto;

  @media (max-width: 1199px){
    padding: 20px 20px 89px;
  }

  @media (max-width: 767px) {
    padding: 35px 20px 50px;
  }

  .form-inner {
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 767px) {
      width: 100%;
    }

  }

  .form-desc {
    p {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

  .form-tel {
    font: 20px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    margin-bottom: 45px;

    @media (max-width: 767px) {
      font-size: 15px;
      margin-bottom: 35px;
    }

    h3 {
      margin-bottom: 10px;

      @media (max-width: 767px) {
        font-size: 16px;
      }  

      &::before {
        display: inline-block;
        content: "■";
        color: #bd8d00;
      }
    }

    .tel-indent {
      padding-left: 20px;

      @media (max-width: 767px){
        padding-left: 0;
      }

      li {
        display: flex;
        align-items: center;

        @media (max-width: 767px){
          display: block;
        }  

        &:first-child {
          margin-bottom: 5px;

          @media (max-width: 767px){
            margin-bottom: 10px;
          }  
  
        }

        span{
          font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
          font-weight: 600;
        }

        img {
          width: 210px;
          height: auto;
          margin-left: 18px;

          @media (max-width: 767px){
            max-width: 150px;
            margin-right: 0;
          }

        }
      }
    }
  }

  .form-error{
    ul{
      margin-bottom: 20px;

      li{
        color: #bb2600;
        font-size: 16px;

        &::before{
          content: "※";
          display: inline-block;
        }

        @media (max-width: 767px){
          font-size: 12px;
        }

      }
    }
  }

  .form-table {
    background: #f0f0f0;
    padding: 60px 50px;

    @media(max-width: 960px) {
      padding: 60px 30px;
    }

    @media (max-width: 767px) {
      padding: 28px 20px 0;
    }

    table {
      width: 100%;
      font-size: 18px;
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-all;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    tr {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 767px) {
        flex-wrap: wrap;
        margin-bottom: 25px;
      }

      &.tall {
        align-items: flex-start;

        .req{
          margin: 10px 35px 7px 0;
        }

        td {
          textarea {
            height: 266px;
            padding: 15px;

            @media (max-width: 767px){
              height: 200px;
            }
          }

          .note {

            @media (max-width: 767px){
              font-size: 14px;
            }

            a {
              color: #cbad00;

              &:hover{
                text-decoration: underline;
              }
            }
          }
        }

      }
    }


    th {
      flex: 0 0 225px;
      text-align: right;
      margin-right: 35px;

      @media (max-width: 767px) {
        text-align: left;
        margin-bottom: 7px;
        font-weight: 600;
      }

      &.req {
        &::after {
          display: inline-block;
          content: "[必須]";
          color: #bb2600;
        }
      }

      &.shift {
        padding-right: 45px;
      }

      &.wish-office{
        @media (max-width: 960px){
          margin-bottom: 7px;
        }
      }

    }

    td {
      flex: 1 1 100%;

      input[type="text"],
      textarea {
        width: 100%;
        background: #fff;
        height: 48px;
        font-size: 18px;
        padding: 0 13px;

        @media (max-width: 767px) {
          height: 34px;
          font-size: 14px;
          padding: 0 10px;
        }
      }

      &.zip {
        span {
          margin: 0 5px;
        }

        input {
          width: 110px;

          &#zipBtn{
            width: auto;
            background: #dedede;
            border: none;
            border-radius: 6px;
            padding: 4px 12px;
            margin-left: 16px;
          }
  
        }
        
      }

      &.office {
        display: flex;
        align-items: center;

        @media (max-width: 1000px) {
          display: block;
          margin-left: 7px;
        }

        span {
          display: flex;
          align-items: center;

          @media (max-width: 1000px){
            margin-bottom: 7px;
          }
        }

        input[type="radio"] {
          width: auto;
          height: auto;
          display: none;
        }

        input[type="radio"] + label {
          cursor: pointer;
          display: inline-block;
          position: relative;
          padding-left: 40px;
          padding-right: 15px;
          height: 40px;
          line-height: 2.222;

          @media (max-width: 767px){
            padding-left: 49px;
            font-size: 14px;
            line-height: 2.9;
          }

          &::before {
            content: "";
            position: absolute;
            display: block;
            width: 28px;
            height: 28px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #FFF;

            @media (max-width: 767px){
              width: 34px;
              height: 34px;
            }
          }
        }

        input[type="radio"]:checked + label {
          &::after {
            content: "";
            position: absolute;
            display: block;
            width: 18px;
            height: 9px;
            margin-top: 9px;
            top: 2px;
            left: 4px;
            transform: rotate(-45deg);
            border-bottom: 2px solid;
            border-left: 2px solid;
            border-color: #000;

            @media (max-width: 767px){
              width: 20px;
              height: 11px;
              top: -1px;
              left: 6px;
            }
          }
        }

      }

      .date {
        .kibou {
          margin-bottom: 15px;
          text-indent: -86px;
          margin-left: 86px;
          line-height: 3;

          @media (max-width: 767px){
            text-indent: inherit;
            margin-left: 7px;
          }

          .date{
            margin-right: 15px;

            @media (max-width: 1199px){
              font-size: 14px;
            }

            @media (max-width: 767px){
              display: block;
              text-decoration: underline;
              line-height: 1;
            }
          }

          .text{
            margin: 0 15px;

            @media (max-width: 1199px){
              margin: 0 5px;
            }

          }

          input {
            display: inline-block;
            width: 48px;

            @media (max-width: 767px){
              width: 34px;
              height: 34px;
            }
          }

        }

        .caution {
          position: relative;
          font-size: 16px;
          padding-left: 20px;

          @media (max-width: 767px){
            font-size: 14px;
            padding-left: 16px;
          }

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "※";
            display: block;
          }
        }
      }
    }

    .submit {
      display: block;
      margin-top: 45px;

      &__btn {
        position: relative;
        display: flex;
        width: 320px;
        height: 40px;
        border: solid 1px #cbad00;
        background: #fff;
        transition: all .4s ease;

        @media(max-width: 960px){
          width: 250px;
        }

        @media (max-width: 767px){
          width: 224px;
          height: 42px;
          margin: 0 auto;
        }

        &:hover {
          background: #cbad00;

          &::before {
            background: url("../img/common/arrow_w_right.png") center / cover no-repeat;
          }

          input[type="submit"]{
            color: #FFF;
          }

        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 55px;
          transform: translateY(-50%);
          background: url(../img/common/arrow_y_right.png) center / cover no-repeat;
          width: 10px;
          height: 15px;

          @media(max-width: 960px){
            right: 25px;
          }

          @media (max-width: 767px){
            right: 20px;
          }
        }

        input[type="submit"] {
          width: 100%;
          -webkit-appearance: none;
          appearance: none;
          background: transparent;
          background-size: 10px;
          border: none;
          font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          color: #4d4d4d;

          @media (max-width: 767px){
            font-size: 16px;
          }
        }
      }
    }
  }
}

/* 確認画面
-----------------------------------------------------------------*/
.contact-form {

  .form-confirm-table{
    width: 100%;
    font-size: 18px;
    table-layout: fixed;
    word-break: break-all;
    word-wrap: break-all;

    table{
      width: 100%;
    }

    tr{
      border-bottom: 1px solid #fff;
    }

    th{
      width: 290px;
      min-width: 200px;
      background: #f0f0f0;
      text-align: right;
      padding: 30px 18px 30px 0;

      @media (max-width: 767px){
        display: block;
        width: 100%;
        text-align: left;
        padding: 8px 22px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    td{
      padding: 30px 0 30px 40px;

      @media (max-width: 767px){
        display: block;
        width: 100%;
        padding: 14px 22px 28px;
        font-size: 14px;
      }

      &.zip{
      }

      &.wish-date{
        .date{
          padding: 0 40px 0 0;

          @media (max-width: 767px){
            padding: 0 14px 0 0;
            text-decoration: underline;
          }
        }
      }

      .content{
        display: block;
        width: 100%;
        max-width: 540px;
        min-width: 280px;

        @media (max-width: 767px){
          max-width: auto;
          min-width: auto;
        }
      }
    }
  }

  .form-confirm-submit{
    display: flex;
    justify-content: center;
    margin-top: 85px;

    @media (max-width: 767px){
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 14px;
    }

    &__btn{
        position: relative;
        display: flex;
        width: 320px;
        height: 40px;
        border: solid 1px #cbad00;
        background: #fff;
        transition: all .4s ease;

        @media(max-width: 960px){
          width: 250px;
        }

        @media (max-width: 767px){
          width: 224px;
          height: 42px;
          margin: 0 auto;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 55px;
          transform: translateY(-50%);
          background: url(../img/common/arrow_y_right.png) center / cover no-repeat;
          width: 10px;
          height: 15px;

          @media(max-width: 960px){
            right: 25px;
          }

          @media (max-width: 767px){
            right: 20px;
          }

          &:hover{
            &::before{
              background: url("../img/common/arrow_w_right.png") center / cover no-repeat;
            }

          }

        }

        &._left{
          margin-right: 55px;

          @media (max-width: 767px){
            margin: 0 auto 12px;
          }

          &::before{
            right: 0;
            left: 55px;
            background: url(../img/common/arrow_y_left.png) center / cover no-repeat;

            @media(max-width: 960px){
              left: 25px;
            }

            @media (max-width: 767px){
              left: 20px;
            }

          }

          &:hover{
            &::before{
              background: url("../img/common/arrow_w_left.png") center / cover no-repeat;
            }

          }

        }

        input[type="submit"] {
          width: 100%;
          -webkit-appearance: none;
          appearance: none;
          background: transparent;
          background-size: 10px;
          border: none;
          font: 18px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          color: #4d4d4d;
          transition: all .4s ease;

          @media (max-width: 767px){
            font-size: 16px;
          }
        }

        &:hover{
          background: #cbad00;

          &::before{
            background: url("../img/common/arrow_w_right.png") center / cover no-repeat;
          }

          input[type="submit"]{
            color: #FFF;
          }
        }

      }

  }


}



/* サンクスページ
-----------------------------------------------------------------*/
.contact-form{

  &__btn{
    .contact-btn{
      width: 320px;
      text-align: center;
      margin: 0 auto;
      transition: all .4s ease;

      @media(max-width: 767px){
        width: 224px;
      }

      a{
        display: block;
        background: url("../img/common/arrow_y_right.png") center right 90px no-repeat;
        background-size: 8px;
        color: #4d4d4d;
        border: 1px solid #cbad00;
        font-size: 16px;
        font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        padding: 4px 0;
        transition: all .4s ease;

        @media (max-width: 767px){
          background: url("../img/common/arrow_y_right.png") 80% 50% / 104px no-repeat;
          background-size: 8px;
          padding: 6px 0;
          font-size: 16px;
        }

        &:hover{
          background: url("../img/common/arrow_w_right.png") center right 90px no-repeat #cbad00;
          background-size: 8px;
          color: #FFF;

          @media (max-width: 767px){
            background: url("../img/common/arrow_y_right.png") 80% 50% / 104px no-repeat;
            background-size: 8px;
            padding: 6px 0;
            font-size: 16px;
          }
  
        }

      }

    }
  }

}



/******************************************************************
 * IE調整
******************************************************************/

.ie{

  .contact-form{
    margin-top: 115px;
    overflow: hidden;

    @media (max-width: 999px){
      margin-top: 135px;
    }
  
    @media (max-width: 767px){
      margin-top: 73px;
    }

    .form-table{
      tr{
        display: inline-flex;

        th{
          width: 20%;
          display: inline-block;
          margin-right: 40px;

          &.shift{
            padding-right: 0;
          }
        }

        td{
          flex: none;
          width: 60%;

          input[type="text"]{
            width: 150%;

            @media (max-width: 999px){
              width: 100%;
            }

        
          }

          textarea{
            width: 68%;

            @media (max-width: 999px){
              width: 60%;
            }

          }

          .date{
            .kibou{
              input{
                width: 48px;
              }
            }
          }

          &.zip{
            input{
              width: 110px;
            }
          }
        }
      }
    }

  }
}


/******************************************************************
 * フッター
******************************************************************/

/* page-top
-----------------------------------------------------------------*/

.page-top{
  a{
    display: block;
    width: 24px;
    padding: 4px 0;
    margin-left: auto;
  }

}

/* footer
-----------------------------------------------------------------*/

footer{
  background: #f0f0f0;
  padding: 20px 0 10px;

  @media (max-width: 767px){
    padding: 40px 0;
    margin-bottom: 44px;
  }

  .inner{
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px){
      flex-direction: column-reverse;
    }

    .footer-left{
      width: 80%;

      @media (max-width: 1199px){
        width: 70%;
      }

      @media (max-width: 767px){
        width: 100%;
      }

      .nav{
        display: flex;
        margin-bottom: 30px;

        @media (max-width: 1119px){
          flex-wrap: wrap;
          width: 65%;
        }

        @media (max-width: 767px){
          display: none;
        }

        li{
          line-height: 1;
          margin-right: 16px;

          @media (max-width: 1119px){
            margin-bottom: 14px;
          }

          &.link-out{
            background: url("../img/common/link_icon.png") center right no-repeat;
            background-size: 14px;
            padding-right: 18px;
          }

          &:last-child{
            margin-right: 0;
          }
        }

        a{
          transition: all .4s ease;

          &:hover{
            color: #926525;
          }
        }
      }

      .youtube{
        margin-bottom: 30px;

        @media (max-width: 767px){
          margin-bottom: 20px;
        }

        .title{
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          @media (max-width: 767px){
            margin-bottom: 8px;
          }

          span{
            display: block;

            &.icon{
              width: 108px;
              margin-right: 10px;

              @media (max-width: 767px){
                width: 100px;
                margin-right: 6px;

                img{
                  display: block;
                }
              }

            }

            &.text{
              line-height: 1;

              @media (max-width: 767px){
                font-size: 13px;
              }

            }
          }
        }

        .link{
          display: flex;

          @media (max-width: 767px){
            display: block;
          }

          li{
            background: url("../img/common/arrow_r.png") center left no-repeat;
            background-size: 6px;
            font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
            padding-left: 12px;
            margin-right: 30px;

            @media (max-width: 767px){
              font-size: 16px;
              letter-spacing: 0px;
              padding-left: 8px;
              margin-right: 10px;
            }

          }
        }
      }

      .address{
        font-size: 14px;

        p{
          line-height: 1.9;

          span{
            padding-left: 8px;

            @media (max-width: 767px){
              display: block;
            }

          }
        }
      }
    }

    .footer-right{
      width: 28%;

      @media (max-width: 1199px){
        width: 30%;
      }

      @media (max-width: 767px){
          width: auto;
          margin-bottom: 40px;
          margin-left: auto;
      }

      .logo{
        width: 306px;
        margin-bottom: 10px;

        @media (max-width: 1119px){
          width: 100%;
          margin-left: auto;
        }

        @media (max-width: 767px){
          width: 252px;
          margin-left: 0;
        }
      }

      .copy{
        font: 11px "游ゴシック体", YuGothic, "Yu Gothic", sans-serif;
        text-align: right;
        margin-bottom: 10px;

        @media (max-width: 1119px){
          font-size: 10px;
        }

        @media (max-width: 767px){
          letter-spacing: 0;
          margin-bottom: 0;
          text-align: right;
        }

      }

      .link{
        font-size: 12px;
        text-align: right;

        @media (max-width: 767px){
          font-size: 14px;
        }

      }
    }
  }
}

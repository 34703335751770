@charset "UTF-8";
/*
Theme Name: 柴田・中川法律特許事務所
*/

@import "modules/reset";
@import "modules/header";
@import "modules/footer";
@import "modules/common";
@import "modules/top";

@import "modules/page";
@import "modules/profile";
@import "modules/price";
@import "modules/kojin";
@import "modules/houjin";
@import "modules/access";

@import "modules/privacy";
@import "modules/contact";


/******************************************************************
 * アクセス
******************************************************************/

/* MVタイトル
-----------------------------------------------------------------*/

.mv-sub-title{

  &.access{
    h2{
      padding-left: 24px;

      @media (max-width: 767px){
        padding-left: 0;
      }

    }

    p{
      padding-left: 30px;

      @media (max-width: 767px){
        padding-left: 0;
      }

    }

    &.inner{
      @media (max-width: 767px){
        width: 94%;
      }
    }
  }
}

/* access-cont
-----------------------------------------------------------------*/

.access-cont{
  padding: 20px 0 40px;

  @media (max-width: 767px){
    padding-bottom: 20px;
  }

  &:first-child{
    padding-top: 40px;

    @media (max-width: 767px){
      padding-top: 30px;
    }

  }

  &:last-child{
    padding-bottom: 80px;

    @media (max-width: 767px){
      padding-bottom: 50px;
    }
  }

  &.inner{
    width: 94%;
  }

  .title{
    position: relative;
    background: linear-gradient(to right, #d2bb58, #f6e28e);
    font: 27px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    padding: 10px 0 18px 50px;
    margin-bottom: 20px;

    @media (max-width: 767px){
      font-size: 20px;
      padding: 10px 0 12px 20px;
    }

    &::after{
      position: absolute;
      left: 0;
      bottom: 6px;
      content: " ";
      width: 100%;
      height: 1px;
      background: #FFF;

      @media (max-width: 767px){
        bottom: 4px;
      }

    }
  }

  .cont-wrap{
    display: flex;
    justify-content: center;
    width: 93%;
    margin: 0 auto 30px;

    @media (max-width: 767px){
      display: block;
      width: 88%;
    }

    .left{
      width: 48%;
      margin-right: 10px;

      @media (max-width: 767px){
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .address{
        margin-bottom: 10px;
      }

      .note{
        margin-left: 16px;

        li{
          display: flex;
          text-indent: -1rem;
          margin-right: 1rem;

          &::before{
            content: "■";
            color: #cbad00;
            margin-right: 2px;
          }
        }
      }
    }

    .right{
      width: 48%;
      margin-left: 10px;

      @media (max-width: 767px){
        width: 100%;
        margin-left: 0;
      }

      li{
        display: flex;
        padding-bottom: 10px;

        @media (max-width: 767px){
          margin-bottom: 8px;
          padding-bottom: 5px;
        }

        span{
          display: block;

          &.icon{
            width: 45px;
            margin-right: 14px;

            @media (max-width: 767px){
              width: 40px;
              margin-right: 8px;
            }

          }

          &:last-child{
            width: 88%;
          }
        }
      }
    }
  }

  iframe{
    width: 100%;
    height: 380px;

    @media (max-width: 767px){
      height: 200px;
    }

  }

  .img{
    p{
      &:nth-of-type(1){
        margin-bottom: 10px;

        @media (max-width: 767px){
          margin-bottom: 4px;
        }

      }
    }
  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{
  .access-cont{
    .cont-wrap{
      .left{
        .note{
          margin-left: 0;

          li{
            text-indent: 0;
            margin-right: 2rem;
          }
        }
      }
    }
  }
  
}


/******************************************************************
 * 個人のお客様へ
******************************************************************/

/* common-intro
-----------------------------------------------------------------*/

.common-intro{
  &.kojin{
    background: #e5e5e5;
  }
}

/* common-cont
-----------------------------------------------------------------*/

.common-cont{
  &.kojin{

    .cont-item{
      h3{
        background: linear-gradient(to right, #d2bb58, #f6e28e);

        span{
        }
      }

      .item-wrap{
        .section-text{
          p{
            span{
              color: #92700b;
              font-weight: bold;
            }
          }
        }

        .btn-list{
          li{
            margin-bottom: 10px;

            @media (max-width: 767px){
              width: 84%;
            }

          }
        }
      }

  /* -------- h3 background-image -------- */

      &.item01{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon01.svg");
              width: 38px;

              @media (max-width: 767px){
                width: 30px;
              }

            }
          }
        }
      }

      &.item02{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon02.svg");
              width: 28px;

              @media (max-width: 767px){
                width: 20px;
              }

            }
          }
        }
      }

      &.item03{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon03.svg");
              width: 40px;

              @media (max-width: 767px){
                width: 32px;
              }

            }
          }
        }
      }

      &.item04{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon04.svg");
              width: 38px;

              @media (max-width: 767px){
                width: 30px;
              }

            }
          }
        }
      }

      &.item05{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon05.svg");
              top: 2px;
              width: 34px;

              @media (max-width: 767px){
                width: 26px;
              }

            }
          }
        }
      }

      &.item06{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon06.svg");
              top: 2px;
              width: 40px;

              @media (max-width: 767px){
                width: 32px;
              }

            }
          }
        }
      }

      &.item07{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon07.svg");
              top: 6px;
              left: -4px;
              width: 46px;

              @media (max-width: 767px){
                width: 38px;
              }

            }
          }
        }
      }

      &.item08{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon08.svg");
              top: 2px;
              width: 40px;

              @media (max-width: 767px){
                width: 32px;
              }

            }
          }
        }
      }

      &.item09{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon09.svg");
              width: 32px;

              @media (max-width: 767px){
                width: 24px;
              }

            }
          }
        }
      }

      &.item10{
        h3{
          span{
            &::before{
              content: url("../img/kojin/kojin_icon10.svg");
              width: 38px;

              @media (max-width: 767px){
                width: 30px;
              }

            }
          }
        }
      }

    }

  }
}


/******************************************************************
 * IE調整
******************************************************************/

.ie{
  .common-cont{
    &.kojin{
      .cont-item{

        &.item01{
          h3{
            span{
              &::before{
                width: 32px;
              }
            }
          }
        }

        &.item02{
          h3{
            span{
              &::before{
                width: 20px;
              }
            }
          }
        }

        &.item03{
          h3{
            span{
              &::before{
                width: 36px;
              }
            }
          }
        }

        &.item04{
          h3{
            span{
              &::before{
                width: 34px;
              }
            }
          }
        }

        &.item05{
          h3{
            span{
              &::before{
                top: 0;
                width: 28px;
              }
            }
          }
        }

        &.item06{
          h3{
            span{
              &::before{
                width: 38px;
              }
            }
          }
        }

        &.item07{
          h3{
            span{
              &::before{
                top: 2px;
              }
            }
          }
        }

        &.item08{
          h3{
            span{
              &::before{
                top: 0;
                width: 36px;
              }
            }
          }
        }

        &.item09{
          h3{
            span{
              &::before{
                top: -3px;
                width: 28px;
              }
            }
          }
        }

        &.item10{
          h3{
            span{
              &::before{
                top: -3px;
                width: 34px;
              }
            }
          }
        }

      }
    }
  }
}
/******************************************************************
 * 弁護士紹・弁理士介
******************************************************************/

/* profile-intro
-----------------------------------------------------------------*/

.profile-intro{
  background: linear-gradient(to bottom, #d2bb58, #f6e28e);
  padding: 40px 0;

  .inner{
    width: 920px;

    @media (max-width: 920px){
      width: 84%;
    }        

    @media (max-width: 767px){
      width: 94%;
    }

    .intro-msg{
      background: url("../img/profile/profile_intro_bg.png") top center no-repeat;
      background-size: 100% 100%;
      font: 22px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
      text-align: center;
      line-height: 2;
      margin-bottom: 40px;

      @media (max-width: 920px){
        font-size: 18px;
      }          

      @media (max-width: 767px){
        background: url("../img/profile/profile_intro_bg_sp.png") top center no-repeat;
        background-size: 100% 100%;
        font-size: 15px;
        text-align: left;
        padding: 0 12px;
      }
  
    }
  
    ul{
      display: flex;
      justify-content: center;

      @media (max-width: 767px){
        display: block;
      }  
  
      li{
        width: 350px;
        border: none;
        margin: 0 10px;

        @media (max-width: 767px){
          width: 70%;
          margin: 0 auto 10px;
        }    
  
        a{
          background: #FFF;

          span{
            position: relative;
            font: 24px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
            letter-spacing: 1px;

            @media (max-width: 920px){
              font-size: 20px;
            }                

            @media (max-width: 767px){
              font-size: 16px;
            }        

            &::after{
              position: absolute;
              content: " ";
              right: -30px;
              bottom: 6px;
              width: 16px;
              height: 16px;
              background: url("../img/common/common_btn_arrow_down.png") no-repeat;
              background-size: 16px;

              @media (max-width: 920px){
                font-size: 20px;
                bottom: 2px;
                background: url("../img/common/common_btn_arrow_down.png") no-repeat;
                background-size: 14px;  
              }                  

              @media (max-width: 767px){
                right: -24px;
                bottom: 0;
                background: url("../img/common/common_btn_arrow_down.png") no-repeat;
                background-size: 12px;
                }          
  
            }
  
          }
        }

        &:hover{
          a{
            color: #333;
          }
        }
      }
    }  

  }
}


/* profile-wrap
-----------------------------------------------------------------*/

.profile-wrap{
  padding-bottom: 60px;

  @media (max-width: 767px){
    padding-top: 73px;
    margin-top: -73px;  
  }

  .location{
    position: relative;
    background: #eee;
    padding: 10px 0;

    &::after{
      position: absolute;
      content: " ";
      bottom: 6px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #FFF;
    }

    span{
      display: block;
      width: 1040px;
      font: 22px "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
      font-weight: bold;
      margin: 0 auto;

      @media (max-width: 1040px){
        width: 767px;
      }        

      @media (max-width: 767px){
        width: 94%;
        font-size: 20px;
      }        

    }

  }

  .prof-box{
    width: 1040px;
    padding: 24px 0 30px;
    margin: 0 auto;

    @media (max-width: 1040px){
      width: 90%;
    }        

    .top{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 20px;

      .image{
        width: 180px;
        margin-right: 20px;

        @media (max-width: 767px){
          width: 100px;
          margin-right: 12px;
        }        
  
      }

      .profile{
        .name{
          font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; 
          font-weight: bold;
          margin-bottom: 10px;

          p{
            font-size: 20px;

            @media (max-width: 767px){
              font-size: 13px;
            }        
    
          }

          h4{
            display: flex;
            align-items: center;
            font-size: 32px;
            line-height: 1.2;

            @media (max-width: 767px){
              display: block;
              font-size: 22px;
              margin-bottom: 6px;
            }        


            span{
              font: 20px "Nanum Gothic Coding", monospace;
              color: #c9ab00;
              font-weight: normal;
              letter-spacing: 4px;
              margin-left: 10px;

              @media (max-width: 767px){
                display: block;
                font-size: 13px;
                letter-spacing: 2px;
                margin-top: 6px;
                margin-left: 0;
              }        
  
            }
          }
        }
      }
    }

    .bottom{
      .list{
        margin-bottom: 14px;
      }

      .channel{
        margin-bottom: 20px; 

        p{
          font-weight: bold;

          img{
            vertical-align: middle;
            width: 28px;
            padding-left: 4px;
            padding-bottom: 4px;
          }
        }

        ul{
          display: flex;

          @media (max-width: 767px){
            justify-content: space-between;
          }        

          li{
            width: 266px;
            margin-right: 10px;

            @media (max-width: 767px){
              width: 49%;
              margin: 0;
            }          
          }
        }
      }

      .misc{
        width: 100%;

        tr{
          th, td{
            padding: 6px 0;
  
            @media (max-width: 767px){
              display: block;
              width: 100%;
              padding: 6px 16px;
            }        
        
          }
  
          th{
            width: 235px;
            background: #eee;
            font-weight: bold;
            border-bottom: 2px solid #FFF;
            padding-left: 20px;
  
            @media (max-width: 767px){
              width: 100%;
              padding-left: 16px;
            }        
  
          }
  
          td{
            position: relative;
            padding-left: 24px;

            @media (max-width: 767px){
              padding-left: 16px;
            }        
  
            &::after{
              position: absolute;
              content: " ";
              left: 24px;
              bottom: 0;
              width: 100%;
              height: 2px;
              background: #eee;
  
              @media (max-width: 767px){
                content: none;
              }        
    
            }
          }  

          &:last-child{
            td{
              @media (max-width: 767px){
                border-bottom: 2px solid #eee;
              }        
            }
          }

        }
      }
    }

    .list{

      p{
        font-weight: bold;
        margin-bottom: 6px;
      }

      .list-wrap{
        position: relative;
        display: flex;
        padding-left: 20px;

        @media (max-width: 767px){
          display: block;
          padding-left: 12px;
        }        

        &::before{
          position: absolute;
          content: " ";
          left: 0;
          width: 6px;
          height: 100%;
          background: linear-gradient(to bottom, #d2bb58, #f6e28e);

          @media (max-width: 767px){
            width: 4px;
          }        
  
        }

        ul{
          margin-right: 24px;

          &:last-child{
            margin-right: 0;
          }

          li{
            line-height: 1.64;
          }
        }
      }
    }
  }

}



/******************************************************************
 * IE調整
******************************************************************/

.ie, .win{
  .profile-intro{
    .inner{
      ul{
        li{
          a{
            span{
              &::after{
                bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  
}



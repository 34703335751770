/******************************************************************
 * Reset CSS - CBT LINKs
******************************************************************/

/* reset margin
-----------------------------------------------------------------*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  zoom: 1;
  /* hasLayout in IE */
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
}

/* reset font style
-----------------------------------------------------------------*/

html {
  box-sizing: border-box;
  font-family: sans-serif;
}

html * {
  box-sizing: inherit;
}

body {
  font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.5px;
  background: transparent;
  color: #333;

  @media (max-width: 1199px){
    font-size: 14px;
  }

  @media (max-width: 999px){
    font-size: 13px;
  }

  @media (max-width: 767px){
    letter-spacing: 0;
    font-size: 14px;
  }

  &.fixed{
    position: fixed;
    width: 100%;
  }

}

ul, ol {
  list-style-type: none;
}

a {
  margin: 0;
  padding: 0;
  color: inherit;
  text-decoration: none;
}

em, strong {
  font-style: normal;
  font-weight: inherit;
}

/* reset table
-----------------------------------------------------------------*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table, th, td {
  table-layout: fixed;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

/* reset form style
-----------------------------------------------------------------*/

input[type="text"],
input[type="tel"],
input[type="email"],
textarea{
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
//  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

button,
input[type="reset"]::-webkit-search-decoration,
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

button,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="button"]:focus {
  outline-offset: -2px;
}

textarea {
  resize: none;
  overflow: auto;
}

*:focus {
  outline: none;
  }


/* reset img
-----------------------------------------------------------------*/

img {
  font-size: 0;
  line-height: 0;
  vertical-align: bottom;
  height: auto;
  max-width: 100%;
}
